.job-costing-header {
    font-weight: bold;
}

.job-costing-component-container {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
}

.job-costing-component {
    width: 19%;
    border-radius: 20px;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    box-sizing: border-box;
}

.job-costing-component-title {
    font-size: 1rem;
    background-color: var(--eerie-black);
    color: white;
    margin: 0;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    padding: 15px 20px;
}

.job-costing-component-content {
    padding: 10px 20px;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
}

.job-costing-component-content-values{
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    margin: 8px 0;
}

.job-costing-component-total {
    font-weight: bold;
}

.job-costing-footer {
    display: flex;
    margin-top: 20px;
    justify-content: space-between;
}

.job-costing-footer-right {
    text-align: right;
    font-weight: bold;
}

.divider {
    height: 1px;
    width: 100%;
    background-color: darkgray;
    margin: 10px 0;
}

.job-costing-man-day-quotient {
    text-align: right;
    color: var(--dark-blue);
    font-weight: bold;
}

.job-costing-component-content-values-right > p {
    margin: 2px 0;
}
.app-background-container {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 10000;
}

.app-loading-container {
    position: absolute;
    background-color: white;
    border-radius: 5px;
    padding: 15px 20px;
    box-sizing: border-box;
    padding-bottom: 40px;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 20%;
    animation: fade 0.4s linear;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

@keyframes fade {
    0% { opacity: 0 }
    100% { opacity: 1 }
}
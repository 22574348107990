.change-order-header {
    display: flex;
    align-items: center;
}

.change-order-title {
    font-size: 1.4rem;
    font-weight: bold;
    margin: 10px 0;
}

.new-change-order-button {
    color: var(--light-blue);
    font-size: 1.5rem;
    cursor: pointer !important;
    font-weight: bold;
    margin-left: 10px;
}

.change-order-table-approved {
    line-height: 2.5rem;
    width: 80%;
    max-width: 200px;
    text-align: center;
}

.change-order-table-approved-yes {
    background-color: #16A69F;
    color: white;
    box-sizing: border-box;
    border-radius: 8px;
}

.change-order-table-approved-no {
    background-color: var(--light-blue-steel);
    color: var(--dark-blue);
    box-sizing: border-box;
    border-radius: 8px;
}
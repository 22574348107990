:root {
  --light-blue: #298BE5;
  --emerald-green: #2ecc71;
  --emerald-green-faded: #56cc88;
  --main-blue: #1C5D99;
  --dark-blue: #123E66;
  --light-blue-steel: #BBCDE5;
  --eerie-black: #222222;
  --cadet-blue: #639FAB;
  --red: #e74c3c;
}

.app-content-container {
  display: flex;
  flex-direction: rows;
  height: 100vh;
}

.flex-row {
  display: flex !important;
  flex-direction: row !important;
  align-items: center;
}
.report-options-container {
  padding: 1rem 0;
  height: 100%;
}

@media print {
  #section-to-print {
      visibility: visible;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 90%;
      z-index: 99999;
      padding: 1em;
      background: white;
  }

  /* #section-to-print * {
      color: black !important;
  }

  #section-to-print tspan {
      fill: black !important;
  } */
}
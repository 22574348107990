.login-page-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
}

.login-page-right-container {
    background-color: var(--light-blue-steel);
    height: 100%;
    width: 40vw;
    padding: 40px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}


.login-page-form-container {
    height: 100%;
    width: 60vw;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    top: -8vh;
}

.login-label {
    font-size: 3rem;
    font-weight: 600;
    margin: 0;
    margin-bottom: 40px;
}

.login-logo {
    position: fixed;
    top: 20px;
    left: 20px;
    font-weight: 400;
    font-size: 1rem;
    margin: 0;
    font-weight: bold;
}

.login-form-input-container {
    display: flex;
    flex-direction: column;
    margin: 20px 0;
}

.logo-icon {
    font-size: 1rem;
    background-color: var(--main-blue);
    color: white;
    font-weight: bold;
    padding: 5px;
    margin-right: 5px;
}

.login-form-content {
    width: 65%;
}

.login-form-submit-button {
    border-radius: 20px;
    margin: 0 auto;
    background-color: var(--light-blue);
    color: white;
    outline: none;
    cursor: pointer;
    width: 100%;
    display: block;
    border: none;
    padding: 20px 0;
    font-weight: bold;
    border-radius: 30px;
}

.login-form-forgot-button {
    background-color: transparent;
    outline: none;
    border: none;
    margin-top: 24px;
    cursor: pointer;
}

.welcome-back-text {
    color: var(--dark-blue);
    font-size: 2.5rem;
    display: block;
    text-align: left;
    width: 100%;
}

.welcome-image {
    max-width: 90%;
}

.welcome-app-title {
    font-size: 0.9rem;
    color: var(--eerie-black);
    text-align: right;
    width: 100%;
}

.login-error {
    color: var(--red);
    font-weight: bold;
    margin-top: -20px;
}
.jobs-table-status {
    line-height: 2.5rem;
    width: 80%;
    max-width: 200px;
    text-align: center;
}

.jobs-table-status-complete {
    background-color: #16A69F;
    color: white;
    box-sizing: border-box;
    border-radius: 8px;
}

.jobs-table-status-inprogress {
    background-color: var(--light-blue-steel);
    color: var(--dark-blue);
    box-sizing: border-box;
    border-radius: 8px;
}
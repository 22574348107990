.not-found-container {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
}

.not-found-container > h1 {
    font-size: 3rem;
    margin: 0;
    margin-bottom: 30px;
}

.not-found-container > h4 {
    font-weight: 500;
    margin: 0;
}
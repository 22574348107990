.MuiDataGrid-root {
    border: none !important;
}

.MuiDataGrid-toolbar {
    padding: 20px 0 !important;
    display: flex;
    justify-content: flex-end;
}

.MuiButtonBase-root:not(.MuiButton-contained) {
    color: var(--dark-blue) !important;
}

.MuiDataGrid-columnsContainer {
    background-color: whitesmoke !important;
    border-radius: 20px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}

.MuiDataGrid-colCellTitle {
    font-weight: bold !important;
}

.MuiDataGrid-colCell{
    background-color: transparent !important;
}

.MuiDataGrid-row {
    cursor: pointer;
}

.MuiDataGrid-row:hover {
    background-color: var(--light-blue-steel);
}

.MuiDataGrid-window {
    overflow-x: hidden !important;
}

.table-cell-error {
    font-weight: bold;
    width: 100%;
    line-height: 2.5rem;
    display: flex;
    align-items: center;
    color: var(--red);
}

.table-cell-error > svg {
    color: var(--red);
    margin-right: 10px;
    font-size: 1.5em;
}
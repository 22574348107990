.change-order-create-button {
    background-color: var(--light-blue);
    color: white;
    outline: none;
    cursor: pointer;
    border: none;
    padding: 20px 40px;
    margin: 0 auto;
    display: block;
    margin-top: 20px;
    font-weight: bold;
    border-radius: 12px;
}

.new-change-order-header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
}

.new-change-order-header-container > h1 {
    font-size: 1.5rem;
    margin: 10px 0;
}

.new-change-order-header-container > svg {
    cursor: pointer;
}

.change-order-item-button {
    margin-left: 5px;
    cursor: pointer;
    font-size: 1.4rem;
}

.change-order-items-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
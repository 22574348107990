.form-container {
    position: absolute;
    top: 0;
    height: 100vh;
    overflow-y: scroll;
    right: -35vw;
    z-index: 99;
    width: 35vw;
    background-color: white;
    height: 100vh;
    padding: 20px 40px;
    box-sizing: border-box;
    -webkit-animation: slide 0.5s forwards;
    animation: slide 0.5s forwards;
}

.react-datepicker-popper {
    z-index: 9999 !important;
}

@-webkit-keyframes slide {
    100% { right: 0; }
}

@keyframes slide {
    100% { right: 0; }
}

.form-container-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.form-container-header > svg {
    cursor: pointer;
}

.form-create-button {
    background-color: var(--light-blue);
    color: white;
    outline: none;
    cursor: pointer;
    width: 100%;
    border: none;
    padding: 20px 0;
    margin: 20px 0;
    font-weight: bold;
    border-radius: 12px;
}

.form-create-button {
    background-color: var(--light-blue);
    color: white;
    outline: none;
    cursor: pointer;
    width: 100%;
    border: none;
    padding: 20px 0;
    margin: 20px 0;
    font-weight: bold;
    border-radius: 12px;
}

.form-create-button--disabled {
    background-color: var(--light-blue-steel);
    cursor:default;
}

.form-input-container {
    display: flex;
    flex-direction: column;
    margin: 20px 0;
}

.form-input-label {
    font-size: 0.8rem;
    margin-top: -5px;
    margin-left: 12px;
    font-weight: 300;
    margin-bottom: 10px;
}

.form-input {
    width: 100%;
    border-radius: 12px;
    font-size: 1rem;
    padding: 15px 20px;
    box-sizing: border-box;
    border: 2px solid var(--light-blue-steel);
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-appearance:none;
    -moz-appearance:none;
}

.form-input:focus {
    border: 2px solid var(--light-blue);
    outline: none;
}

.suggestion-item {
    width: 100%;
    background-color: whitesmoke;
    margin: 10px 0;
    padding: 15px 20px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
    box-sizing: border-box;
    border-radius: 15px;
    cursor: pointer;
}

.suggestion-item:hover {
    background-color: var(--light-blue-steel);
}

.suggestion-item--active {
    width: 100%;
    border-radius: 15px;
    background-color: var(--light-blue-steel);
    margin: 10px 0;
    padding: 15px 20px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
    box-sizing: border-box;
}

.autocomplete-loading {
    padding: 10px 20px;
}

.checkbox-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}

.form-checkbox-option {
    text-align: center;
    background-color: whitesmoke;
    padding: 15px 0;
    width: 48%;
    border-radius: 15px;
    cursor: pointer;
}

.form-checkbox-option:hover {
    transform: scale(1.05);
    transition: 0.3s;
}

.form-checkbox-option--active {
    background-color: var(--cadet-blue) !important;
    font-weight: bold;
    color: white;
    cursor: pointer;
}

.form-checkbox-check {
    margin-right: 10px;
}

.amount-buttons-container {
    display: flex;
    width: 100%;
    margin: 0 auto;
    justify-content: flex-end;
    font-size: 1.5rem;
    cursor: pointer;
}

#add-amount-button {
    color: var(--main-blue);
}

#remove-amount-button {
    color: var(--red);
    margin-left: 10px;
}

.form-delete-button {
    font-size: 1rem;
    width: 100%;
    display: flex;
    font-weight: bold;
    justify-content: center;
    cursor: pointer;
    color: darkred;
}

.form-buttons-container{
    display: flex;
    justify-content: space-between;
}

.form-buttons-container button{
    width: 48%;
}

.pdf-view-button{
    color: black;
    outline: none;
    cursor: pointer;
    width: 100%;
    border: none;
    padding: 20px 0;
    font-weight: bold;
    margin-bottom: 20px;
    border-radius: 12px;
    background-color: #90dbc5;
}

.pdf-view-button--disabled{
    cursor:default;
}
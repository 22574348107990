.edit-job-form-container {
    position: absolute;
    top: 0;
    height: 100vh;
    overflow-y: scroll;
    z-index: 100;
    background-color: white;
    height: 100vh;
    padding: 20px 40px;
    box-sizing: border-box;
    transition: 0.5s;
}

.edit-job-form-container-info {
    right: -35vw;
    width: 35vw;
    width: 35vw !important;
    -webkit-animation: slide 0.5s forwards;
    animation: slide 0.5s forwards;
}

.edit-job-form-container-costing {
    right: -85vw;
    width: 85vw !important;
    -webkit-animation: slide 0.5s forwards;
    animation: slide 0.5s forwards;
}

@-webkit-keyframes slide {
    100% { right: 0; }
}

@keyframes slide {
    100% { right: 0; }
}

.edit-job-form-container-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.edit-job-form-container-header > svg {
    cursor: pointer;
}

.edit-job-create-button {
    background-color: var(--light-blue);
    color: white;
    outline: none;
    cursor: pointer;
    width: 100%;
    border: none;
    padding: 20px 0;
    margin-top: 20px;
    font-weight: bold;
    border-radius: 12px;
}

.edit-job-tab-container {
    display: flex;
    width: 65%;
    max-width: 250px;
    margin: 10px auto;
    border: 2px solid var(--main-blue);
    border-radius: 20px;
    font-size: 0.9rem;
    overflow: hidden;
}

.edit-job-tab-item {
    cursor: pointer;
    text-align: center;
    background-color: whitesmoke;
    padding: 10px 0;
    width: 50%;
}

.edit-job-tab-item--selected {
    background-color: var(--main-blue);
    color: white;
}
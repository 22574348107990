.page-header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 40px 0;
    margin-bottom: 20px;
}

.page-container {
  padding: 40px 6vw;
  flex-grow: 5;
  display: flex;
  flex-direction: column;
}

.page-title {
  font-size: 2rem;
  font-weight: bold;
}

.page-background-container {
    position: fixed;
    top: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, .4);
    z-index: 1000;
}

.page-alert-super-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center; 
  z-index: 2000;
}

.page-alert-container {
  padding: 32px;
  width: 35vw;
  max-height: 80%;
  overflow-y: scroll;
  background-color: white;
  border-radius: 15px;
  z-index: 3000;
}

.page-search-container {
    display: flex;
    align-items: center;
    width: 25vw;
    border-radius: 10px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border: 2px solid var(--light-blue-steel);
    padding: 15px 15px;
}

.page-search-container--active {
    border: 2px solid var(--light-blue) !important;
}

.page-search-input {
    font-size: 1rem;
    border: none;
    margin: 0 10px;
    flex-grow: 2;
}

.page-search-input:focus {
    outline: none;
}

.page-new-button {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    background-color: var(--light-blue);
    border: none;
    font-weight: bold;
    cursor: pointer;
    border-radius: 30px;
    font-size: 1rem;
    padding: 0 30px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.page-new-button:hover {
    outline: none;
    transform: scale(1.05);
    transition: 0.5s;
}

.page-new-button:focus {
    outline: none;
}

.page-header-left-container {
    display: flex;
    align-items: center;
}

.page-search-filter-button {
    margin-left: 10px;
    padding: 15px 15px;
    background-color: var(--light-blue-steel);
    font-weight: bold;
    color: var(--main-blue);
    border-radius: 10px;
    cursor: pointer;
}

.page-search-filter-button--inactive {
    background: none !important;
}
.labor-form-calc-container {
    padding: 20px 10px;
    box-sizing: border-box;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.labor-form-calc-container > .form-input-label {
    margin: 5px 6px;
}
.nav-bar-container {
    width: 5vw;
    transform-origin: left;
    position: relative;
    overflow: hidden;
    height: 100vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: var(--main-blue);
    padding: 20px 0;
    transition: width .3s ease-in-out;
    justify-content: space-between;
    box-sizing: border-box;
}

.nav-bar-top, .nav-bar-bottom {
    width: 100%;
}

.nav-bar-title-container {
    display: flex;
    width: 100%;
    padding: 15px 0;
    margin-left: calc(5vw * 0.25);
}

.nav-bar-title-container-collapsed {
    justify-content: center;
    margin-left: 0;
}

.nav-bar-title {
    color: white;
    font-weight: bold;
    text-align: left;
    font-size: 1.6rem;
}

.nav-bar-title-compressed {
    color: white;
    font-weight: bold;
    text-align: center;
    font-size: 1.6rem;
    margin-bottom: 20px;
}

.nav-bar-collapse {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 20px;
    box-sizing: border-box;
    font-size: 1.5rem;
    cursor: pointer;
}

.nav-bar-item {
    width: 100%;
    display: flex;
    justify-content: left;
    align-items: center;
    padding: 15px 10px;
    box-sizing: border-box;
    font-size: 1.5rem;
    cursor: pointer;
}

.nav-bar-item:hover, .nav-bar-item-collapsed:hover {
    background-color: var(--light-blue);
}

.nav-bar-item > svg {
    width: 25% !important;
}

.nav-bar-item-title {
    font-size: 1rem;
    margin-left: 6px;
    color: white;
}

.nav-bar-item-collapsed {
    width: 100%;
    display: flex;
    font-size: 1.5rem;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 15px 0px;
}

.nav-bar-link {
    width: 100%;
    text-decoration: none;
}

.nav-bar-item-selected {
    background-color: var(--dark-blue) !important;
    box-sizing: border-box;
}

.nav-bar-close-icon {
    font-size: 2rem;
    width: 90%;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
}

.nav-bar-expand-icon {
    font-size: 2rem;
    cursor: pointer;
    display: flex;
    justify-content: center;
}